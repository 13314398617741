import React from "react";

const About = () => {
  return (
    <div name="about" className="w-full h-screen bg-[#0a192f] text-gray-300">
      <div className="flex flex-col justify-center items-center w-full h-full">
        <div className="max-w-[1000px] w-full grid grid-cols-2 gap-8">
          <div className="sm:text-right pb-8 pl-4">
            <p className="text-4xl font-bold inline border-b-4 border-pink-600">
              About
            </p>
          </div>
          <div></div>
        </div>
        <div className="max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4">
          <div className="sm:text-right text-4xl font-bold">
            <p>
              Hi. I'm Ammar Adnan, nice to meet you. Please take a look around.
            </p>
          </div>
          <div>
            <p className="text-justify">
              As a computer science professional with experience in software
              development, strong technical skills, and a proven ability to work
              well in teams and solve complex problems, I am confident in my
              ability to take on new challenges. My technical skills include
              HTML, React JS, Tailwind CSS, JavaScript, C++, Python, and
              Angular. If you're looking for a talented and driven computer
              science professional, please don't hesitate to contact me.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
