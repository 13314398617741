import Sociaxity from "../assets/projects/sociaxity.png";
import Catatur from "../assets/projects/catatur.png";
import Mshow from "../assets/projects/mshow.png";
import Mateshop from "../assets/projects/mateshop.png";

export const data = [
  {
    id: 1,
    name: "Sociaxity",
    image: Sociaxity,
    github: "https://github.com/Ammaradnan12/Sociaxity-Landing-Page",
    live: "https://sociaxity.site/",
  },
  {
    id: 2,
    name: "Coming Soon......",
    image: Catatur,
    github: "",
    live: "",
    // github: "https://github.com/Ammaradnan12/catatur",
    // live: "https://catatur.online/",
  },
  {
    id: 3,
    name: "Coming Soon......",
    image: Mateshop,
    github: "",
    live: "",
  },
];
